@import "./utils/colors.scss";
@import "./overrides.scss";
@import "./animations.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-mall{
  color: #e9074f !important;
}

.font-weight-700 {
  font-weight: 700;
}

.main{
   display: flex;
   flex-direction: column;
   min-height: 100vh;
}

.mall-btn,
.mall-btn:hover {
  background-color: #e9074f !important;
  border-color: #e9074f !important;
  color:white !important;
}