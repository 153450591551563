// OVERRIDES
.app {
    .form-control:focus {
        border-color: rgba($mallplazaPrimary, .5);
        box-shadow: 0 0 0 0.2rem rgba($mallplazaPrimary, .5);
    }

    .table td,
    .table th {
        vertical-align: middle;
    }

    .btn.btn-primary {
        background-color: $mallplazaPrimary;
        border-color: $mallplazaPrimary;
        color: white;
        font-size: 0.8rem;

        &:hover {
            background-color: $mallplazaPrimary;
            color: #ffffff;
        }

        &:not(:disabled):not(.disabled) {

            &:active {
                border-color: $mallplazaPrimary;
                background-color: $focusMallPlazaPrimary;
            }

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($mallplazaPrimary, .5);
            }
        }

        &.rounded-btn {
            border-radius: 30px;
            font-weight: bold;
            padding: 10px 50px;

            &:hover {
                background-color: #d20040;
                box-shadow: 0 0 5px 1px rgba($mallplazaPrimary, .5);
            }
        }
    }

    .mas-info-btn {
        font-size: 0.8rem;
        padding: 8px 12px;
    }

    .text-primary {
        color: $mallplazaPrimary !important;
    }

    .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        border-color: $mallplazaPrimary;
        background-color: $mallplazaPrimary;
    }
    .custom-control-input:not(:disabled):active~.custom-control-label::before {
        color: #fff;
        background-color: $mallplazaPrimary;
        border-color: $mallplazaPrimary;
    }
    .custom-control-input:focus~.custom-control-label::before {
        box-shadow: 0 0 0 0.2rem rgba($mallplazaPrimary,.25);
    }
    .custom-control-input:focus:not(:checked)~.custom-control-label::before {
        border-color: $mallplazaPrimary;
    }
}

.modal-footer {
    button.btn.btn-primary {
        background-color: $mallplazaPrimary;
        border-color: $mallplazaPrimary;
        color: white;
        font-size: 0.8rem;

        &:hover {
            background-color: $mallplazaPrimary;
            color: #ffffff;
        }

        &:not(:disabled):not(.disabled) {

            &:active {
                border-color: $mallplazaPrimary;
                background-color: $focusMallPlazaPrimary;
            }

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($mallplazaPrimary, .5);
            }
        }

        &.rounded-btn {
            border-radius: 30px;
            font-weight: bold;
            padding: 10px 50px;

            &:hover {
                background-color: #d20040;
                box-shadow: 0 0 5px 1px rgba($mallplazaPrimary, .5);
            }
        }
    }
}

button.dropdown-item {
    
    &:active, &.active {
        background-color: $focusMallPlazaPrimary;
        color: #ffffff;
        
        a {
            color: #ffffff;
        }
    }

    &:focus {
        outline: none;
    }

    a {
        &:hover {
            text-decoration: none;
        }
        &:active {
            color: #ffffff;
        }
    }
}

.spinner-border.text-primary {
    color: $mallplazaPrimary !important;
}
