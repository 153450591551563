@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeIn {
    animation: 0.5s fadeIn;
}

.fadeOut {
    animation: 0.5s fadeOut;
}

.b-fadeInOut-enter {
    animation: 0.25s fadeIn;
}

.b-fadeInOut-enter-active {
    transition: animation .25s ease-in-out;
}

.b-fadeInOut-exit {
    animation: .25s fadeOut;
}

.b-fadeInOut-exit-active {
    transition: animation .25s ease-in-out;
}
